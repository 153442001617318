import styled from 'styled-components';
import { mobileThreshold } from '../../Constants/layoutValues';

export const CvWrapper = styled.div`
	display: flex;
	flex-direction: row;
	@media screen {
		font-size: 1.15em;
		line-height: 1.6em;
		max-width: 1600px;
	}

	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
`;

export const BodySection = styled('div')`
	display: flex;
	flex-direction: column;
`;
