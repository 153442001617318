import * as React from 'react';
import { BodySection, CvWrapper } from './cvStyles';
import { AboutSectionLayout, Props as AboutSectionProps } from '../../Components/AboutSection/AboutSectionLayout';
import { ProfessionalExperienceSectionLayout, Props as ProfessionalExperienceProps } from '../../Components/ProfessionalExperienceSection/ProfessionalExperienceSectionLayout';
import { EducationPanelLayout, Props as EducationPanelProps } from '../../Components/EducationPanel/EducationPanelLayout';
import { SchemaType as EducationSchemaType } from '../../Schema/CVs/default/Education';
import { cssVarColors } from '../../Constants';
import { useSearchParams } from 'react-router-dom';
import { CvFilterType } from '../../Types/cvFilterType';
import { ProfessionalExperienceEntriesType } from '../../Types/ProfessionalExperienceLineType';

import { SchemaType as SkillsSchemaType } from '../../Schema/CVs/default/Skills';
import { SchemaType as TechnologySchemaType } from '../../Schema/CVs/default/Technologies';
import { SchemaType as ContactSchemaType } from '../../Schema/CVs/default/ContactInfo';
import { SchemaType as ToolsSchemaType } from '../../Schema/CVs/default/Tools';
import { SchemaType as IdeologiesSchemaType } from '../../Schema/CVs/default/Ideologies';
import { SchemaType as CoreCompetenciesSchemaType } from '../../Schema/CVs/default/Core Competencies';

export type Props = {
	educationSchema: EducationSchemaType;
	professionalExperienceSchema: ProfessionalExperienceEntriesType;
	aboutMeSchemas: {
		skillsSchema: SkillsSchemaType;
		technologySchema: TechnologySchemaType;
		contactSchema: ContactSchemaType;
		toolsSchema: ToolsSchemaType;
		ideologiesSchema: IdeologiesSchemaType;
		coreCompetenciesSchema: CoreCompetenciesSchemaType;
	};
};

export const CvLayout = (props: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const render = () => {
		let filter = searchParams.get('filter');

		const educationProps: EducationPanelProps = {
			title: props.educationSchema.title,
			backgroundColor: cssVarColors.onSurface,
			textColor: '#fff',
			lines: props.educationSchema.lines,
		};
		const aboutSectionProps: AboutSectionProps = {
			filter: filter as CvFilterType,
			aboutMeSchemas: props.aboutMeSchemas,
		};
		const professionalExperienceProps: ProfessionalExperienceProps = {
			professionExperienceSchema: props.professionalExperienceSchema,
		};

		const returnValue = (
			<CvWrapper>
				<AboutSectionLayout {...aboutSectionProps} />
				<BodySection>
					<ProfessionalExperienceSectionLayout {...professionalExperienceProps} />
					<EducationPanelLayout {...educationProps} />
				</BodySection>
			</CvWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
