import * as React from 'react';
import { AboutSectionWrapper, ContactContentWrapper } from './AboutSectionStyles';
import { language } from './AboutSectionLanguage';
import { AboutMeLayout } from './AboutMe/AboutMeLayout';
import { FancyHeaderLayout } from '../FancyHeader/FancyHeaderLayout';
import { MeritPanelLayout, Props as MeritPanelProps } from '../MeritPanel/MeritPanelLayout';
import { cssVarColors } from '../../Constants';
import { generateContactInfo, Props as ContactInfoProps } from './ContactInformation/helpers/generateContactInfoLines';
import { CvFilterType } from '../../Types/cvFilterType';
import { SchemaType as SkillsSchemaType } from '../../Schema/CVs/default/Skills';
import { SchemaType as TechnologySchemaType } from '../../Schema/CVs/default/Technologies';
import { SchemaType as ContactSchemaType } from '../../Schema/CVs/default/ContactInfo';
import { SchemaType as ToolsSchemaType } from '../../Schema/CVs/default/Tools';
import { SchemaType as IdeologiesSchemaType } from '../../Schema/CVs/default/Ideologies';
import { SchemaType as CoreCompetenciesSchemaType } from '../../Schema/CVs/default/Core Competencies';

export type Props = {
	filter: CvFilterType;
	aboutMeSchemas: {
		skillsSchema: SkillsSchemaType;
		technologySchema: TechnologySchemaType;
		contactSchema: ContactSchemaType;
		toolsSchema: ToolsSchemaType;
		ideologiesSchema: IdeologiesSchemaType;
		coreCompetenciesSchema: CoreCompetenciesSchemaType;
	};
};

export const AboutSectionLayout = (props: Props) => {
	const render = () => {
		const skillsProps: MeritPanelProps = {
			title: props.aboutMeSchemas.skillsSchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: props.aboutMeSchemas.skillsSchema.lines,
		};

		const techProps: MeritPanelProps = {
			title: props.aboutMeSchemas.technologySchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: props.aboutMeSchemas.technologySchema.lines,
		};

		const toolsProps: MeritPanelProps = {
			title: props.aboutMeSchemas.toolsSchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: props.aboutMeSchemas.toolsSchema.lines,
		};

		const ideologies: MeritPanelProps = {
			title: props.aboutMeSchemas.ideologiesSchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: props.aboutMeSchemas.ideologiesSchema.lines,
		};

		const coreCompetencies: MeritPanelProps = {
			title: props.aboutMeSchemas.coreCompetenciesSchema.title,
			filter: props.filter,
			backgroundColor: cssVarColors.surfaceAlternate,
			textColor: '#fff',
			lines: props.aboutMeSchemas.coreCompetenciesSchema.lines,
		};

		const contactInfoProps: ContactInfoProps = {
			contactInfoLines: props.aboutMeSchemas.contactSchema.lines,
		};
		const contactJSX = generateContactInfo(contactInfoProps);
		const contactProps: MeritPanelProps = {
			title: props.aboutMeSchemas.contactSchema.title,
			filter: props.filter,
			textColor: '#fff',
			backgroundColor: cssVarColors.surfaceAlternate,
			jsx: <ContactContentWrapper>{contactJSX}</ContactContentWrapper>,
		};

		const returnValue = (
			<AboutSectionWrapper>
				<FancyHeaderLayout />
				<AboutMeLayout />
				<MeritPanelLayout {...ideologies} />
				<MeritPanelLayout {...coreCompetencies} />
				<MeritPanelLayout {...skillsProps} />
				<MeritPanelLayout {...toolsProps} />
				<MeritPanelLayout {...techProps} />
				<MeritPanelLayout {...contactProps} />
			</AboutSectionWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
